import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import { Link } from "gatsby"

function Header() {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <nav className="bg-indigo-100 w-full z-10 fixed">
      <div className="container max-w-6xl mx-auto flex justify-between px-6">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <a href="/" className="flex items-center">
            <span className="sr-only">Vantage Circle</span>
            <img
              className="w-auto vc-logo mb-0"
              src="https://res.cloudinary.com/vantagecircle/image/upload/w_280/v1608528238/blog_images/images/vantagecircle-white-300.png"
              alt="Vantage Circle Logo"
              width="300"
            />
          </a>
        </div>
        <div className="flex items-center justify-end h-16">
          <div className="flex items-center">
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  to="/vantage-rewards/"
                  className="text-white hover:text-orange px-3 py-2 text-sm font-medium"
                >
                  Vantage Rewards
                </Link>

                <Link
                  to="/vantage-perks/"
                  className="text-white hover:text-orange px-3 py-2 text-sm font-medium"
                >
                  Vantage Perks
                </Link>

                <Link
                  to="/vantage-pulse/"
                  className="text-white hover:text-orange px-3 py-2 text-sm font-medium"
                >
                  Vantage Pulse
                </Link>

                <Link
                  to="/vantage-fit/"
                  className="text-white hover:text-orange px-3 py-2 text-sm font-medium"
                >
                  Vantage Fit
                </Link>

                <Link
                  to="/vantage-redemption/"
                  className="text-white hover:text-orange px-3 py-2 text-sm font-medium"
                >
                  Vantage Redemption
                </Link>

                <div className="dropdown relative text-white hover:text-orange px-3 py-2 text-sm font-medium">
                  <div className="inline-flex items-center cursor-pointer">
                    <div className="more-items">. . .</div>
                  </div>
                  <ul className="dropdown-menu absolute hidden text-gray-700 m-0 bg-white rounded-sm mt-2 submenu shadow-lg overflow-hidden">
                    {/* <li><a className="rounded-b bg-white hover:bg-purple-800 py-3 px-4 block whitespace-no-wrap transition duration-500 ease-in-out" href="/vantage-redemption/">Vantage Redemption</a></li> */}
                    <li>
                      <a
                        className="rounded-t bg-white hover:bg-purple-800 py-3 px-4 block whitespace-no-wrap transition duration-500 ease-in-out"
                        href="https://blog.vantagecircle.com/"
                        target="_blank"
                      >
                        Blogs
                      </a>
                    </li>
                    <li>
                      <Link
                        className="bg-white hover:bg-purple-800 py-3 px-4 block whitespace-no-wrap transition duration-500 ease-in-out"
                        to="/how-to-videos/"
                      >
                        <strong>How to</strong> Videos
                      </Link>
                    </li>
                    <li>
                      <a
                        className="rounded-b bg-white hover:bg-purple-800 py-3 px-4 block whitespace-no-wrap transition duration-500 ease-in-out"
                        href="https://www.vantagecircle.com/product-updates/"
                        target="_blank"
                      >
                        Product Updates
                      </a>
                    </li>
                  </ul>
                </div>

                <a
                  href="https://www.vantagecircle.com/contact-us/"
                  className="bg-purple-500 text-white hover:text-white px-3 py-2 rounded-xs text-sm font-medium"
                >
                  Need Support?
                </a>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-purple-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden"
          >
            <span className="sr-only">Open menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className={isOpen ? "" : "hidden"}>
        <div className="fixed z-50 top-0 inset-x-0 transition transform origin-top-right lg:hidden shadow-nav ">
          <div className="bg-white divide-y-2 divide-purple-50">
            <div className="pt-5 pb-6 px-4">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png"
                    alt="Vantage Circle"
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-purple-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="p-2 mt-3 max-h-96">
                <nav className="grid gap-y-8 mobile-menu">
                  {/* Mobile Menu starts */}
                  <div className="content">
                    <div className="acc">
                      <Link
                        to="/vantage-rewards/"
                        className="-m-3 mb-2 p-3 flex items-center"
                      >
                        <span className="flex-1 text-sm md:text-base font-normal text-gray-900">
                          Vantage Rewards
                        </span>
                      </Link>
                      <Link
                        to="/vantage-perks/"
                        className="-m-3 mb-2 p-3 flex items-center"
                      >
                        <span className="flex-1 text-sm md:text-base font-normal text-gray-900">
                          Vantage Perks
                        </span>
                      </Link>
                      <Link
                        to="/vantage-pulse/"
                        className="-m-3 mb-2 p-3 flex items-center"
                      >
                        <span className="flex-1 text-sm md:text-base font-normal text-gray-900">
                          Vantage Pulse
                        </span>
                      </Link>
                      <Link
                        to="/vantage-fit/"
                        className="-m-3 mb-2 p-3 flex items-center"
                      >
                        <span className="flex-1 text-sm md:text-base font-normal text-gray-900">
                          Vantage Fit
                        </span>
                      </Link>

                      <div className="acc__card">
                        <div className="acc__title text-base haschildren">
                          Additional Resources
                        </div>
                        <div className="acc__panel">
                          <a
                            href="https://blog.vantagecircle.com/"
                            target="_blank"
                            className="-m-3 p-3 flex items-center rounded-md"
                          >
                            <span className="text-sm md:text-base font-normal text-gray-900">
                              Blogs
                            </span>
                          </a>
                          <Link
                            to="/how-to-videos/"
                            className="-m-3 p-3 flex items-center rounded-md"
                          >
                            <span className="text-sm md:text-base font-normal text-gray-900">
                              <strong>How to</strong> Videos
                            </span>
                          </Link>
                          <a
                            href="https://www.vantagecircle.com/product-updates/"
                            target="_blank"
                            className="-m-3 p-3 flex items-center rounded-md"
                          >
                            <span className="text-sm md:text-base font-normal text-gray-900">
                              Product Updates
                            </span>
                          </a>
                        </div>
                      </div>
                      <a
                        href="https://www.vantagecircle.com/contact-us/"
                        className="bg-purple-500 text-white hover:text-white px-3 py-2 rounded-xs text-sm font-medium"
                      >
                        Need Support?
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
