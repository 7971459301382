import React from "react";

import Layout from "../components/layout"

const HowToVideos = () => {
  
  return (
    <Layout includeSearchBar={false}>
        <div className="bg-indigo-100 relative top-search-form">
			<div className="container flex items-center px-6 pt-20 md:pt-32 py-20 pb-10">
				<div className="max-w-4xl mx-auto text-center relative w-full">
					<div className="w-full">
						<h1 className="text-white text-2xl md:text-5xl mb-4 font-bold">
							<span className="text-orange">How To</span> Videos
						</h1>
                    </div>
                </div>
            </div>
        </div>
        <section className="webinar-list container px-6 lg:px-0 max-w-4xl mx-auto pt-5 sm:pt-10 pb-20">
            <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=Rx9KkgEb1Cs" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/Rx9KkgEb1Cs/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to Earn Cashback </h2>
                                <p class="box-subtitle mb-2">At Vantage Circle, you can earn extra benefits and cashback on every online shopping experience, over and above the discounts, you are already receiving from the merchant site!</p>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=iOFmh0XQoDc" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/iOFmh0XQoDc/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to start appreciating in MS Teams</h2>
                                <p class="box-subtitle mb-2">With the help of the Vantage Circle app installed, you can easily recognize your colleagues directly from Microsoft Teams. Login to your Microsoft Teams accounts and move to the channel inside a team where your administrator has installed the app.</p>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=haJuG43L7Ko" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/haJuG43L7Ko/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to Install & Configure MS teams</h2>
                                <p class="box-subtitle mb-2">By implementing Vantage Rewards with your Microsoft Teams, you can do the same from within the team channel without visiting our platform. It enables you to appreciate and nominate employees for awards in your respective Microsoft Teams groups.</p>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=1-I6C_MZC_0" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/1-I6C_MZC_0/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to recognise Employees in Slack</h2>
                                <p class="box-subtitle mb-2">Employee recognition, appreciation, and nomination have now become a seamless process with the Vantage Circle App’s integration in Slack. Watch this video to learn how the  Vantage Circle Slack App makes it quick and easy for you to reward and recognize your employees along with communication and collaboration.</p>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=KkCcFXgAIKM" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/KkCcFXgAIKM/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to install Vantage Circle in Zoho </h2>
                                <p class="box-subtitle mb-2">Maintaining and keeping track of an employee database is an important but uphill task for HR managers. Keeping that in mind, Vantage Circle has integrated with Zoho People to provide the HR managers a seamless way to transfer employee data without any hassle.</p>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=Y8sHRDZ9vKU" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/Y8sHRDZ9vKU/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to Integrate Freshteams in Vantage Circle</h2>
                                <p class="box-subtitle mb-2">In this video, we walk you through the simple step-by-step process on how to integrate the Vantage Circle platform into your Freshteam Dashboard. This integration will enable HR administrators to sync the employee onboarding and exit data to your Vantage Circle Admin account.</p>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=YmGWzrDOLrc" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/YmGWzrDOLrc/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to Use Manager Dashboard</h2>
                                <p class="box-subtitle mb-2">Manager Dashboard lets you see valuable insights about how your team members appreciate each other through the social feed.In this video, we’ll walk you through the in-depth steps of navigating the Manager Dashboard and how to use it to get the optimum amount of insights.</p>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div class="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg">
                        <a href="https://www.youtube.com/watch?v=r5xwUtVdyNQ" target="_blank">
                            <div class="img-overlay relative cursor-pointer ">
                                <img loading="lazy" class="mx-auto rounded-lg shadow-md" src="https://img.youtube.com/vi/r5xwUtVdyNQ/maxresdefault.jpg" alt="Vantage Circle Video" />
                                <div class="iconplay d-flex align_items_center">
                                    <svg class="w-14 md:w-20 h-14 md:h-20 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                                </div>
                            </div>
                            <div class="bg-white py-3 md:py-6 md:pb-0 h-full topcard no-br">
                                <h2 class="box-title-v1 font-bold mb-3 line-clamp-2 sm:h-14">How to integrate Vantage circle with Monday.com</h2>
                                <p class="box-subtitle mb-2">In this video, we walk you through the simple step-by-step process of how to integrate the Vantage Circle platform with Monday.com. This integration gives you access to Vantage Circle, where you can recognize and award your coworkers to keep them motivated.</p>
                            </div>
                        </a>
                    </div>
                </article>
            </div>
        </section>
    </Layout>
    )
  }
  
  export default HowToVideos